import { Floor } from "pages/Projects/data";
import { EditorError } from "pages/Projects/types";

export enum SelectedObjectType {
    ArContent = "arContent",
    Poi = "poi",
}

interface SelectedObject {
    id: string;
}

export interface EditorState {
    selectedObjects: SelectedObject[];
    selectedObjectType: SelectedObjectType;
    selectedFloor?: Floor;
    floors: Floor[];
    errors: EditorError[];
    objectInitialPosition: THREE.Vector3;
    showMapOverlay: boolean
    isMapOverlayAvailable: boolean
}