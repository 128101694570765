import { useEffect, useState } from 'react';
import { getAllProjects, getProjectByProjectId } from "apis/project";
import { getARContentsByProjectId } from "apis/arContent";
import { getPlaceById, getPointClouds } from 'apis/pointCloud';
import { getFloors } from 'apis/floor';
import { getPois } from 'apis/poi';

export enum ARAnchorType {
    WorldAnchor = "world_anchor",
    ImageAnchor = "image_anchor",
    HorizontalPlaneAnchor = "horizontal_plane_anchor",
    VerticalPlaneAnchor = "vertical_plane_anchor",
    FaceAnchor = "face_anchor"
}

export enum VersionType {
    Published = "published",
    Draft = "draft"
}

export enum ARContentType {
    Model3d = "model3d",
    Video = "video",
    Image = "image",
    DetectionImage = "detection_image",
    FaceParent = "face_parent",
    ReferenceImage = "reference_image",
}

export enum SdkMode {
    WorldAnchor = "world_anchor",
    FaceAnchor = "face_anchor",
    PointCloudAnchor = "point_cloud_anchor"
}

export enum EffectTriggerType {
    Tap = "tap",
}

export enum EffectActionType {
    OpenUrl = "open_url",
    Collect = "collect",
}

export interface Project {
    _id?: string
    accessTokenRefId?: string
    name: string
    version: VersionType
    lastModified: number
    createdAt: number
    lastOpenedAnchorType: ARAnchorType
    previewLocations: PreviewLocation[]
    firebaseAuthUid?: string // Ref to user
    organizationId: string
}

export interface PreviewLocation {
    name: string
    description: string
    location2D: Location2D
    altitude: number
    isDefault: boolean
}

export interface PointCloudPlace {
    _id: string
    name: string
    category: number
    type: string
    publicPointCloudFile: string
    publicSplatFile: string
    location: LocationPolygon
    originCoordinate: PointCloudOrigin
}

export interface LocationPolygon {
    type: 'Polygon'
    coordinates: number[][]
}

export interface PointCloudOrigin {
    displayOriginCoordinates: number[]
    displayRotation: Quaternion
    multiplyScalar: number
}

export interface Floor {
    _id: string
    displayName: string
    placeId: string
    index: number
    isDefault: boolean
    yMin?: number
    yMax?: number
}

export interface Effect {
    trigger: EffectTriggerType
    actionType: EffectActionType
    enabled?: boolean
    openUrl?: string
}

export interface ARContent {
    _id?: string
    arContentId: string
    projectRefId: string
    parentId?: string // For image anchor
    accessTokenRefId?: string
    lastModified: number
    createdAt: number
    version: VersionType

    arContentType: ARContentType
    arAnchorType: ARAnchorType
    sizeInBytes?: number

    // Content Properties
    position: Vector3
    scale: Vector3
    rotation: Vector3

    // File & Network
    userFilename: string
    userFilenameIos: string
    fileExtWithoutDot: string
    storeId?: string
    storeUrl?: string
    storeUrlIos?: string
    downloadUrl: string
    previewUrl?: string
    iosDownloadUrl: string
    isPublicStore: boolean

    location2D: Location2D
    altitude: number

    renderRadiusInMeter: number
    isFrontFacing: boolean
    isIndoorPointCloud: boolean
    indoorPointCloudRefId?: string
    floorId?: string

    effects?: Effect[]

    // For custom props
    // [key: string]: any;
}

export interface Poi {
    _id?: string
    projectRefId: string
    pointCloudRefId: string
    displayName: string
    description: string
    position: Vector3
    floorId?: string
}

export interface GroupObject {
    position: Vector3
    rotation: Vector3
    scale: Vector3
}

export interface Location2D {
    type: 'Point'
    coordinates: number[]  // Lng, La, Alt
}

export interface Vector3 {
    x: number
    y: number
    z: number
}

export interface Quaternion {
    w: number
    x: number
    y: number
    z: number
}

interface CameraData {
    position: Vector3
    quaternion: Quaternion
}

export interface EditorStorage {
    lastOpenedAnchorType: ARAnchorType
    lastOpenedObjectId: string
    latestCameraData: CameraData
}

interface FetchData<T> {
    data: T,
    isFetching: boolean
    isFinished: boolean
}

export const useAllProjectsData = (organizationId: string) => {

    const [dataState, setDataState] = useState<FetchData<Project[]>>({ data: [], isFetching: false, isFinished: false });

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setDataState({ ...dataState, isFetching: true });
                let projects = await getAllProjects(organizationId);
                if (!projects) projects = [];
                // console.log("projects", projects)
                setDataState({
                    ...dataState,
                    data: projects,
                    isFetching: false,
                    isFinished: true
                });
            } catch (e) {
                console.error(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (organizationId !== "") {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [dataState];
};

export const useProjectData = (projectId: string) => {

    const [dataState, setDataState] = useState<FetchData<Project>>({ data: {} as Project, isFetching: false, isFinished: false });

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setDataState({ ...dataState, isFetching: true });
                let project = await getProjectByProjectId(projectId);

                setDataState({
                    ...dataState,
                    data: project,
                    isFetching: false,
                    isFinished: true
                });
            } catch (e) {
                console.error(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (localStorage.getItem("graffity-console-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [dataState];
};

export const useARContentsData = (projectId: string): [FetchData<ARContent[]>, () => void] => {
    const [dataState, setDataState] = useState<FetchData<ARContent[]>>({ data: [], isFetching: false, isFinished: false });

    const refreshData = () => {
        setDataState({ data: [], isFetching: true, isFinished: false });
        getARContentsByProjectId(projectId).then(res => {
            setDataState({
                ...dataState,
                data: res,
                isFetching: false,
                isFinished: true
            })
        }).catch(e => {
            console.error(e)
        })
    }

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setDataState({ ...dataState, isFetching: true });
                let arContents = await getARContentsByProjectId(projectId);
                if (!arContents) arContents = [];
                setDataState({
                    ...dataState,
                    data: arContents,
                    isFetching: false,
                    isFinished: true
                });
            } catch (e) {
                console.error(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (localStorage.getItem("graffity-console-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [dataState, refreshData];
};


export const usePointCloudsData = (organizationId: string): [PointCloudPlace[], boolean] => {

    const [pointClouds, setPointClouds] = useState<PointCloudPlace[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const data = await getPointClouds(organizationId);
                setPointClouds(data);
                setIsFetching(false)
            } catch (e) {
                console.error(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("graffity-console-user") && pointClouds.length === 0) {
            fetchDataFromApi();
        }
    }, []); // Runs once

    return [pointClouds, isFetching];
};

export const usePointCloudData = (placeId: string): [PointCloudPlace | null, boolean] => {

    const [pointCloud, setPointCloud] = useState<PointCloudPlace | null>(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const data = await getPlaceById(placeId);
                setPointCloud(data);
                setIsFetching(false)
            } catch (e) {
                console.error(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("graffity-console-user") && placeId) {
            fetchDataFromApi();
        }
    }, [placeId]);

    return [pointCloud, isFetching];
};

export const useFloorsData = (placeId: string): [Floor[], boolean] => {
    const [floors, setFloors] = useState<Floor[]>([])
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const data = await getFloors(placeId);
                if (data) {
                    setFloors(data);
                }
                setIsFetching(false)
            } catch (e) {
                console.error(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("graffity-console-user")) {
            fetchDataFromApi();
        }
    }, []); // Runs once

    return [floors, isFetching];
}

export const usePoisData = (projectId: string, pointCloudId: string): [FetchData<Poi[]>, () => void] => {
    const [dataState, setDataState] = useState<FetchData<Poi[]>>({ data: [], isFetching: false, isFinished: false });

    const refreshData = () => {
        setDataState({ data: [], isFetching: true, isFinished: false });
        getPois(projectId, pointCloudId).then(res => {
            setDataState({
                ...dataState,
                data: res,
                isFetching: false,
                isFinished: true
            })
        }).catch(e => {
            console.error(e)
        })
    }

    useEffect(() => {
        const fetchDataFromApi = async () => {
            if (!projectId || !pointCloudId) return
            try {
                setDataState({ ...dataState, isFetching: true });
                let pois = await getPois(projectId, pointCloudId);
                if (!pois) pois = [];
                setDataState({
                    ...dataState,
                    data: pois,
                    isFetching: false,
                    isFinished: true
                });
            } catch (e) {
                console.error(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (localStorage.getItem("graffity-console-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [dataState, refreshData];
};
