import { ARContent, ARContentType } from "pages/Projects/data";
import { connect } from "react-redux";
import { EditorState } from "store/editor/types";
import { ApplicationState } from "store/data";
import { addSelectedArContent, changeSelectedArContent, clearSelectedObjects } from 'store/editor/actions';
import { Dispatch } from 'redux';
import "./arContentRow.scss"
import { Box } from "components/Common/Layout/Layout";
import { LiteButton } from "components/Common/LiteButton/LiteButton";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useState } from "react";
import { createOrUpdateARContent } from "apis/arContent";
import { nanoid } from "nanoid";
import { isArContentSelected } from "../../utils";

interface OwnProps {
    arContent: ARContent
    onAddARContent?: (object: ARContent) => void
}

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type ARContentRowProps = StateProps & OwnProps & DispatchProps

const ARContentRowComponent = ({
    arContent,
    editorState,
    dispatch,
    onAddARContent,
}: ARContentRowProps) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const isOpen = isArContentSelected(arContent, editorState)
    const isIosContentReady = !(arContent.arContentType === ARContentType.Model3d && !arContent.storeUrlIos)
    const isError = editorState.errors.reduce((prev, current) => current.objectid === arContent._id || prev, false)

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen)
    }

    const toogleIsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        // GC-611 disable multi select until we make a proper logic for group
        // if (e.shiftKey) {
        //     dispatch(addSelectedArContent(arContent._id || ''))
        //     return
        // }
        if (isOpen) {
            dispatch(clearSelectedObjects())
        } else {
            dispatch(changeSelectedArContent(arContent._id || ''))
        }
    }

    const duplicateArContent = async() => {
        if (!onAddARContent) return
        const newArContent = {
            ...arContent,
            arContentId: nanoid(),
        }
        newArContent._id = undefined
        const newId = await createOrUpdateARContent(newArContent)
        newArContent._id = newId
        onAddARContent(newArContent)
    }

    const getARContentFileIcon = (type: ARContentType) => {
        switch (type) {
            case ARContentType.Image:
                return "bi bi-image"

            case ARContentType.Model3d:
                return "bi bi-box"

            case ARContentType.Video:
                return "bi bi-play-circle"

            default:
                return "bi bi-file-earmark-text"
        }
    }

    return (
        <div className="arContentRow">
            <LiteButton
                onClick={toogleIsOpen}
                data-testid="header-button"
                highlight={isOpen}
                border="solid"
                variant={isError ? "error" : "normal"}
                block
            >
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <div className="me-3"><i className={`font-size-16 align-middle ${getARContentFileIcon(arContent.arContentType)}`}></i></div>
                        <div className="arContentTitle fs-6">{`${arContent.userFilename}.${arContent.fileExtWithoutDot}`}</div>
                    </Box>
                    <Box display="flex">
                        <i className={`font-size-16 align-middle me-2 bi bi bi-apple ${isIosContentReady ? "text-success text-opacity-75" : "text-light"}`}></i>
                        <i className={`font-size-16 align-middle bi bi-android2 text-success text-opacity-75`}></i>
                        {
                            onAddARContent && <Dropdown
                                isOpen={popoverOpen}
                                toggle={togglePopover}
                                direction="end"
                            >
                                <DropdownToggle
                                    tag='i'
                                    className={`font-size-16 align-middle bi bi-three-dots-vertical text-dark text-opacity-75 ms-1`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        togglePopover()
                                    }}
                                />
                                <DropdownMenu>
                                    <DropdownItem tag='div' onClick={duplicateArContent}>
                                        Make a Copy
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        }
                    </Box>
                </Box>
            </LiteButton>
        </div>
    )
}

ARContentRowComponent.displayName = "ARContentRowComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const ARContentRow = connect(mapStateToProps)(ARContentRowComponent);

export { ARContentRow, ARContentRowComponent }
