import { customAlphabet } from "nanoid";
import React from "react"
import { useSearchParams } from "react-router-dom";
import toastr from "toastr";
import { ARAnchorType, ARContent, ARContentType } from "../../../data";

import "toastr/build/toastr.min.css";
import { ARContentRow } from "./ARContentRow";
import { EditorState } from "store/editor/types";
import { isArContentSelected } from "../../utils";

const MAX_ANCHOR_IMAGE_SIZE = 10485760 // 10MB
export const iosFileTypes = ".usdz"
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 15);

export enum ToastType {
    info, warning, error, success
}

export const showToastr = (type: ToastType, message: string) => {
    toastr.options = {
        positionClass: "toast-bottom-left",
        showEasing: "swing",
        hideEasing: "linear",
        showDuration: 300,
        hideDuration: 500,
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
    }

    if (type === ToastType.info) toastr.info(message, "");
    else if (type === ToastType.warning) toastr.warning(message, "");
    else if (type === ToastType.error) toastr.error(message, "");
    else toastr.success(message, "");
}

const shouldShowArContentInList = (
    arContent: ARContent,
    pointCloudId: string | null,
    detectionImageId: string | null,
    faceAnchorId: string | null,
    editorState: EditorState,
) => {
    if (arContent.arAnchorType === ARAnchorType.ImageAnchor) {
        return ((arContent.arContentType !== ARContentType.DetectionImage) && (arContent.parentId) && (arContent.parentId === detectionImageId))
    }
    if (arContent.arAnchorType === ARAnchorType.FaceAnchor) {
        return (arContent.arContentType !== ARContentType.FaceParent) && (arContent.parentId) && (arContent.parentId === faceAnchorId)
    }
    if (arContent.arAnchorType === ARAnchorType.WorldAnchor) {
        if (pointCloudId) {
            const floorId = editorState.selectedFloor?._id
            const isPlaceContent = arContent.isIndoorPointCloud && arContent.arContentType !== ARContentType.ReferenceImage && (arContent.indoorPointCloudRefId === pointCloudId)
            if (floorId) return isPlaceContent && (floorId === arContent.floorId || isArContentSelected(arContent, editorState) || (!arContent.floorId && editorState.selectedFloor?.isDefault))
            return isPlaceContent
        }
        return !arContent.isIndoorPointCloud
    }
    return true
}

interface ARContentMenuProps {
    arContents: ARContent[]
    editorState: EditorState
    onAddARContent: (object: ARContent) => void
}

const ARContentMenu = ({ arContents, editorState, onAddARContent }: ARContentMenuProps) => {
    const [urlParams] = useSearchParams();
    const detectionImageId = urlParams.get('detection_image_id');
    const faceAnchorId = urlParams.get('face_anchor_id');
    const pointCloudId = urlParams.get('point_cloud_id');
    const arContentDisplayList = arContents.filter((arContent) => shouldShowArContentInList(arContent, pointCloudId, detectionImageId, faceAnchorId, editorState))


    return (
        <React.Fragment>

            {/* Show List AR Contents */}
            <div className="mb-5">
                <div className="accordion" id="accordion">
                    {
                        arContentDisplayList.length === 0
                            ? <p className="text-dark">There is no asset uploaded yet. Please upload your models.</p>
                            : arContentDisplayList.map((arContent, index) => (
                                <React.Fragment key={"anchor-row-" + index}>
                                    {
                                        <ARContentRow
                                            arContent={arContent}
                                            data-testid="ar-content-row"
                                            onAddARContent={onAddARContent}
                                        />
                                    }
                                </React.Fragment>
                            ))}
                </div>
            </div>
        </React.Fragment>
    )
}


export { shouldShowArContentInList, ARContentMenu };